export const SET_MESSAGE = "SET_MESSAGE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_CATALOGS = "SET_CATALOGS";
export const SET_BRAND = "SET_BRAND";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";

// async

export const GET_CATALOGS_SAGA = "GET_CATALOGS_SAGA";
export const GET_BRAND_SAGA = "GET_BRAND_SAGA";
export const PLACE_GIFT_CARD_ORDER_SAGA = "PLACE_GIFT_CARD_ORDER_SAGA";
export const GET_EXCHANGE_RATE_SAGA = "GET_EXCHANGE_RATE_SAGA";
