export const COUNTRIES = [
  { name: "Austria", iso2Code: "AT" },
  { name: "Belgium", iso2Code: "BE" },
  { name: "Germany", iso2Code: "DE" },
  { name: "Spain", iso2Code: "ES" },
  { name: "France", iso2Code: "FR" },
  { name: "India", iso2Code: "IN" },
  { name: "Ireland", iso2Code: "IE" },
  { name: "Netherlands", iso2Code: "NL" },
  { name: "Portugal", iso2Code: "PT" },
  { name: "United Kingdom", iso2Code: "UK" },
  { name: "United States", iso2Code: "US" },
  { name: "Canada", iso2Code: "CA" },
  { name: "Australia", iso2Code: "AU" },
  { name: "Italy", iso2Code: "IT" },
  { name: "Poland", iso2Code: "PL" },
  { name: "Sweden", iso2Code: "SE" },
  { name: "Singapore", iso2Code: "SG" },
];
