export const SET_MESSAGE = "SET_MESSAGE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_RECOGNITION_POLICIES = "SET_RECOGNITION_POLICIES";
export const SET_RECOGNITION_POLICY_SETTINGS =
  "SET_RECOGNITION_POLICY_SETTINGS";
export const SET_SLACK_CHANNELS = "SET_SLACK_CHANNELS";
export const SET_TEAMS = "SET_TEAMS";
export const SET_TEAM_DETAILS = "SET_TEAM_DETAILS";
export const SET_COMPANY_VALUES = "SET_COMPANY_VALUES";

// async

export const GET_SLACK_CHANNELS_SAGA = "GET_SLACK_CHANNELS_SAGA";

export const GET_ALL_TEAMS_SAGA = "GET_ALL_TEAMS_SAGA";
export const GET_TEAM_DETAILS_SAGA = "GET_TEAM_DETAILS_SAGA";
export const CREATE_TEAM_SAGA = "CREATE_TEAM_SAGA";
export const UPDATE_TEAM_SAGA = "UPDATE_TEAM_SAGA";
export const DELETE_TEAM_SAGA = "DELETE_TEAM_SAGA";

export const CREATE_COMPANY_VALUES_SAGA = "CREATE_COMPANY_VALUES_SAGA";
export const GET_COMPANY_VALUES_SAGA = "GET_COMPANY_VALUES_SAGA";
export const UPDATE_COMPANY_VALUES_SAGA = "UPDATE_COMPANY_VALUES_SAGA";
export const DELETE_COMPANY_VALUES_SAGA = "DELETE_COMPANY_VALUES_SAGA";
