export const SET_MESSAGE = "SET_MESSAGE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_TEAM_GETTING_STARTED_STEPS = "SET_TEAM_GETTING_STARTED_STEPS";
export const SET_TEAM_ACTIVITY = "SET_TEAM_ACTIVITY";

// async

export const GET_TEAM_GETTING_STARTED_STEPS_SAGA =
  "GET_TEAM_GETTING_STARTED_STEPS_SAGA";
export const GET_TEAM_ACTIVITY_SAGA = "GET_TEAM_ACTIVITY_SAGA";
export const ENABLE_APP_FOR_TEAM_SAGA = "ENABLE_APP_FOR_TEAM_SAGA";
