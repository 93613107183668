export const SET_MESSAGE = "SET_MESSAGE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_REWARD_STATS = "SET_REWARD_STATS";
export const SET_ADD_CREDIT_CARD_DIALOG_CONFIG =
  "SET_ADD_CREDIT_CARD_DIALOG_CONFIG";
export const SET_TOP_UP_DIALOG_CONFIG = "SET_TOP_UP_DIALOG_CONFIG";
export const SET_TEAM_POINT_TOP_UPS = "SET_TEAM_POINT_TOP_UPS";
export const SET_PAYMENT_DETAILS = "SET_PAYMENT_DETAILS";

// async

export const GET_TEAM_POINT_TOP_UPS_SAGA = "GET_TEAM_POINT_TOP_UPS_SAGA";
export const GET_REWARD_STATS_SAGA = "GET_REWARD_STATS_SAGA";
export const PURCHASE_POINTS_SAGA = "PURCHASE_POINTS_SAGA";
export const GET_PAYMENT_DETAILS_SAGA = "GET_PAYMENT_DETAILS_SAGA";

export const GET_PAYMENT_METHODS_SAGA = "GET_PAYMENT_METHODS_SAGA";
export const CREATE_SETUP_INTENT_SAGA = "CREATE_SETUP_INTENT_SAGA";
export const SAVE_CARD_DETAILS_SAGA = "SAVE_CARD_DETAILS_SAGA";
