import React from "react";

const PageNotFound = () => {
  return (
    <div className="">
      <div className="">Page Not Found</div>
      <div className="">Something has gone awry</div>
    </div>
  );
};

export default PageNotFound;
